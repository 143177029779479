<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :loading="loading"
        :to="to"
        :class="{ 'on-hover': hover }"
        :elevation="hover ? 12 : 2">
      <slot></slot>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "Card",
  props: {
    loading: {
      value: false
    },
    to: {
      value: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  transition: box-shadow .4s ease-in-out;
}
</style>