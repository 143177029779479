<template>
  <div class="home">
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'users'}"
          >
            <v-card-title>
              Total Users
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ users.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'circles'}"
          >
            <v-card-title>
              Total Circles
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ circles.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'payments'}"
          >
            <v-card-title>
              Total Packets Sold
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ payments.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
      <v-row>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'users'}"
          >
            <v-card-title>
              New Users in {{ currentMonth }}
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ monthlyUsers.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'circles'}"
          >
            <v-card-title>
              New Circles in {{ currentMonth }}
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ monthlyCircles.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
        <v-col cols="4">
          <Card
              :loading="loading"
              :to="{name: 'payments'}"
          >
            <v-card-title>
              New Packages Sold in {{ currentMonth }}
            </v-card-title>
            <v-card-text class="text-center">
              <h1 class="text-h1">{{ monthlyPayments.length }}</h1>
            </v-card-text>
          </Card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Pending Commercial Circle Requests</v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                      Circle Name
                    </th>
                    <th>
                      Company Name
                    </th>
                    <th>
                      Representative
                    </th>
                    <th>
                      Phone
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Requested on
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="request in nonCompletedCompanyRequests"
                      :key="request.id"
                  >
                    <td>{{ request.circle.name }}</td>
                    <td>{{ request.companyName }}</td>
                    <td>{{ request.name }}</td>
                    <td>{{ request.phone }}</td>
                    <td>{{ request.email }}</td>
                    <td>{{ formatDateTime(request.requestDate) }}</td>
                    <td class="text-right">
                      <v-btn color="success" @click="approveCompanyRequest(request)">Approve</v-btn>
                    </td>
                    <td class="text-right">
                      <v-btn color="error" @click="rejectCompanyRequest(request)">Reject</v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'
import Card from "@/components/Card"
import dateFormat from '../mixins/date'

export default {
  name: 'Dashboard',
  mixins: [dateFormat],
  components: {Card},
  data: () => ({
    loading: false
  }),
  async created() {
    this.loading = true
    await this.$store.dispatch('fetchData')
    this.loading = false
  },
  computed: {
    currentMonth() {
      return moment().format('MMMM')
    },
    ...mapState(['users', 'circles', 'companyRequests', 'payments', 'monthlyUsers', 'monthlyCircles', 'monthlyPayments']),
    nonCompletedCompanyRequests() {
      return this.companyRequests.filter(request => request.completed === false)
    }
  },
  methods: {
    approveCompanyRequest(companyRequest) {
      this.axios.put(`company-requests/${companyRequest.id}`).then(() => {
        companyRequest.completed = true
      })
    },
    rejectCompanyRequest(companyRequest) {
      this.axios.delete(`company-requests/${companyRequest.id}`).then(() => {
        companyRequest.completed = true
      })
    }
  }
}
</script>
